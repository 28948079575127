import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { FeatureFlagContainer, SetGlobalStyle } from '@vezeeta/web-components';
import { BrowserRouter as Router, Switch } from 'react-router-dom';
import EnterpriseLayout from '@vezeeta/enterprise-layout';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { MixpanelConsumer } from 'react-mixpanel';
import { language } from '@vezeeta/enterprise-store/lib/types/language';
import { loadRelativeTypes } from '@vezeeta/enterprise-store/lib/actions/relativeTypes';
import { withTranslation } from 'app/withTranslation/withTranslation';
import RoleBasedRoute from 'app/RoleBasedAccess/RoleBasedRoute';
import { PatientsList, PatientProfile, CreatePrescription, EditPrescription } from 'app/Routes';
import { getFeatureFlags, getbreadCrumbSteps, isSupportSingleLanguage } from './App.helper';
import { extendContentContainer } from './App.style';

const App = ({ ...props }) => {
  useEffect(() => {
    SetGlobalStyle();
  }, []);

  useEffect(() => {
    props.loadRelativeTypes(language);
  }, [language]);

  const { user, isUserLoaded, clinics, localization } = props;
  const supportSingleLanguage = isSupportSingleLanguage(user, isUserLoaded);

  return (
    <FeatureFlagContainer.Provider value={getFeatureFlags()}>
      <Router>
        <EnterpriseLayout
          supportSingleLanguage={supportSingleLanguage}
          breadcrumbProps={getbreadCrumbSteps(localization)}
          activeTab="Patients"
          extendContentContainer={extendContentContainer}
        >
          <React.Fragment>
            <MixpanelConsumer>
              {mixpanel => (
                <Switch>
                  <RoleBasedRoute
                    path="/"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <PatientsList
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/patientprofile"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <PatientProfile
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/createprescription"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <CreatePrescription
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                      />
                    )}
                  />
                  <RoleBasedRoute
                    path="/editprescription"
                    exact
                    isUserLoaded={isUserLoaded}
                    localization={localization}
                    render={routeProps => (
                      <EditPrescription
                        {...routeProps}
                        {...props}
                        mixpanel={mixpanel}
                        supportSingleLanguage={supportSingleLanguage}
                        clinics={clinics}
                        localization={localization}
                        language={language}
                      />
                    )}
                  />
                </Switch>
              )}
            </MixpanelConsumer>
          </React.Fragment>
        </EnterpriseLayout>
      </Router>
    </FeatureFlagContainer.Provider>
  );
};

const mapStateToProps = state => ({
  isUserLoaded: state.user.loaded,
  user: state.user.user,
  clinics: state.clinics.Clinics,
  relativeTypes: state.relativeTypes.relativeTypes,
  relativeTypesLoadState: state.relativeTypes.relativeTypesLoadState,
});

App.propTypes = {
  isUserLoaded: PropTypes.bool.isRequired,
  user: PropTypes.object,
  clinics: PropTypes.array,
  localization: PropTypes.object,
};

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      loadRelativeTypes,
    },
    dispatch,
  );

export default withTranslation(connect(mapStateToProps, mapDispatchToProps)(App));

import { decodeJwtToken, Cookie } from '@vezeeta/web-utils';

const USER_ROLES = {
  OWNER: 'Owner',
  DOCTOR: 'Doctor',
  ASSISTANT: 'Assistant',
  NURSE: 'Nurse',
  ACCOUNTANT: 'Accountant',
  SINGLE_DR_APP: 'SINGLE_DR_APP',
};

export const USER_PRIVILEGES = {
  AUTHORIZED: 'AUTHORIZED',
  NOT_AUTHORIZED: 'NOT_AUTHORIZED',
  PERSONALIZED: 'PERSONALIZED',
};

const getInitComponentsPrivilege = components => {
  const componentsPrivileges = {};
  components.forEach(component => {
    componentsPrivileges[component] = {
      canReadAndWrite: USER_PRIVILEGES.AUTHORIZED,
    };
  });
  return componentsPrivileges;
};

export const getUserRole = () => {
  if (Cookie.get(Cookie.AUTH_TOKEN)) {
    const userToken = decodeJwtToken(Cookie.get(Cookie.AUTH_TOKEN)).payLoad;
    const userRoles = userToken.role;
    if (userRoles && userRoles.length) {
      if (userRoles.includes('Owner') && !userRoles.includes('Doctor')) return USER_ROLES.OWNER;
      if (!userRoles.includes('Owner') && userRoles.includes('Doctor')) return USER_ROLES.DOCTOR;
      if (userRoles.includes('Assistant')) return USER_ROLES.ASSISTANT;
      if (userRoles.includes('Owner') && userRoles.includes('Doctor'))
        return USER_ROLES.SINGLE_DR_APP;
      if (userRoles.some(role => role === 'Nurse')) return USER_ROLES.NURSE;
      if (userRoles.some(role => role === 'Finance')) return USER_ROLES.ACCOUNTANT;
    }
  }
  return '';
};

export const getRoleBasedAccess = () => {
  const initComponentsPrivilege = getInitComponentsPrivilege([
    'patientsList_view',
    'addEditPatient_btn',
    'addAppointment_btn',
    'patientProfile_view',
    'editPresc_btn',
  ]);

  const userRole = getUserRole();

  switch (userRole) {
    case USER_ROLES.OWNER:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      return initComponentsPrivilege;
    case USER_ROLES.SINGLE_DR_APP:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      return initComponentsPrivilege;
    case USER_ROLES.ACCOUNTANT:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      return initComponentsPrivilege;
    case USER_ROLES.NURSE:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      return initComponentsPrivilege;
    case USER_ROLES.DOCTOR:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      return initComponentsPrivilege;
    case USER_ROLES.ASSISTANT:
      initComponentsPrivilege.editPresc_btn.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      initComponentsPrivilege.patientsList_view.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addEditPatient_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.addAppointment_btn.canReadAndWrite = USER_PRIVILEGES.AUTHORIZED;
      initComponentsPrivilege.patientProfile_view.canReadAndWrite = USER_PRIVILEGES.NOT_AUTHORIZED;
      return initComponentsPrivilege;

    default:
      break;
  }

  return initComponentsPrivilege;
};

import Loadable from 'react-loadable';
import { Loading } from '@vezeeta/web-components';

export const PatientsList = Loadable({
  loader: () => import('views/PatientListContainer'),
  loading: Loading,
});

export const PatientProfile = Loadable({
  loader: () => import('views/PatientProfileContainer'),
  loading: Loading,
});

export const CreatePrescription = Loadable({
  loader: () => import('views/CreatePrescriptionContainer'),
  loading: Loading,
});

export const EditPrescription = Loadable({
  loader: () => import('views/EditPrescriptionContainer'),
  loading: Loading,
});
